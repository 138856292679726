<template>
    <div class="question-item">
        <div v-if="mapStyle === 'map'" class="map-box">
            <div class="map-info">
                <i class="nazaio-iconfont nz-analy-area"></i
                ><span>{{
                    locationInfo.name ? locationInfo.name : "地理位置"
                }}</span>
            </div>
            <p>{{ tooltip || "提示信息" }}</p>
            <div class="map-layer">
                <el-amap class="map-box" :center="center" :zoom="16">
                    <el-amap-marker
                        :visible="true"
                        :position="center"
                    ></el-amap-marker>
                </el-amap>
            </div>
        </div>
        <div v-else-if="mapStyle === 'text'" class="map-container">
            <div class="map-info">
                <i class="nazaio-iconfont nz-analy-area"></i
                ><span
                    ><b>{{ mapTitle ? mapTitle : "位置信息"
                    }}：</b>{{
                        locationInfo.name ? locationInfo.name : "地址名称"
                    }}</span
                >
            </div>
            <div class="tool-info">
                <i class="nazaio-iconfont nz-info-circle"></i>
                <p>{{ tooltip || "提示信息" }}</p>
            </div>
            <div class="map-icon">
                <i class="nazaio-iconfont nz-send"></i>
            </div>
        </div>
    </div>
</template>
<script>
import { getIpInfo, getDistrict } from "@/api/amap";

export default {
    name: "Map",
    props: ["mapTitle", "locationInfo", "tooltip", "mapStyle"],
    created() {
        getIpInfo().then((response) => {
            let adcode = "110000";
            if (response.status == "1") {
                adcode = response.adcode;
            }
            getDistrict(adcode).then((res) => {
                if (
                    res.status == "1" &&
                    res.districts &&
                    res.districts.length
                ) {
                    this.defaultCenter = res.districts[0].center.split(",");
                }
            });
        });
    },
    data() {
        return {
            defaultCenter: [0, 0],
        };
    },
    computed: {
        center() {
            if (!this.locationInfo.location) {
                return this.defaultCenter;
            }
            return this.locationInfo.location.split(",");
        },
    },
};
</script>
<style lang="less" scoped>
.map-box {
    width: 305px;
    margin: 0 auto;
    background: #ffffff;
    border: 1px solid #eff2f5;
    border-radius: 5px;
    box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.15);

    .map-info {
        overflow: hidden;
        height: 22px;
        line-height: 22px;
        margin-top: 12px;

        i {
            float: left;
            color: @fc-main-color;
            width: 20px;
            margin-left: 17px;
        }

        span {
            float: left;
            margin-left: 5px;
            width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            color: #0a2a4c;
        }
    }

    p {
        font-size: 14px;
        color: #8494a6;
        line-height: 22px;
        margin-left: 17px;
        margin-top: 4px;
        margin-bottom: 12px;
        margin-right: 17px;
    }

    .map-layer {
        width: 305px;
        height: 180px;
    }

    .map-box {
        width: 100%;
        height: 100%;
    }
}

.map-container {
    position: relative;

    .map-info {
        overflow: hidden;
        height: 22px;
        line-height: 22px;
        margin-right: 60px;
        position: relative;

        i {
            color: @fc-main-color;
            width: 20px;
        }

        span {
            margin-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            color: #0a2a4c;
            position: absolute;
            left: 20px;
            top: 0;
            right: 0;

            b {
                font-weight: bold;
            }
        }
    }

    .tool-info {
        overflow: hidden;
        margin-top: 6px;
        position: relative;
        margin-right: 60px;

        i {
            color: @fc-main-color;
            width: 20px;
            color: #FFC552;
            font-size: 12px;
        }

        p {
            margin-left: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            color: #8494a6;
            line-height: 18px;
            position: absolute;
            left: 20px;
            top: 2px;
            right: 0;
        }
    }

    .map-icon {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background-color: @fc-main-color;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;
        top: 0;

        i {
            display: block;
            font-size: 20px;
            color: #fff;
        }
    }
}
</style>